<template>
  <SellersList />
</template>

<script>
  export default {
    components: {
      SellersList: () => import('@/components/people/sellers/SellersList')
    }
  }
</script>